import { Avatar, Flex, Menu, MenuButton } from '@chakra-ui/react';

import { NavMenuItem, NavMenuSubItemList, useAuth, useNavItemStore } from '@cksoftware/react-base';
import { ApplicationRoutes } from '../../constants/appRoutes';
import { elementUser } from '../../globalModels/user/elementUser';

export const NavMenus = () => {
  const auth = useAuth<elementUser>();

  const routeNav = useNavItemStore((state) => {
    return { items: state.getDefault(), get: state.get };
  });

  return (
    <Flex fontSize={{ base: 'x-small', xl: 'small', '2xl': 'medium' }} gap={{ base: '4px', xl: '8px', '2xl': '20px' }}>
      {routeNav.items.map((item, index) => {
        return <NavMenuItem key={index} item={item} />;
      })}
      {auth.isAuthenticated && (
        <Menu size={'xs'}>
          <MenuButton>
            <Avatar
              borderWidth={'1px'}
              borderStyle={'solid'}
              backgroundColor={'primary.500'}
              color={'white'}
              size={{ base: 'xs', xl: 'sm', '2xl': 'md' }}
              name={`${auth.user?.FirstName} ${auth.user?.LastName}`}
            />
          </MenuButton>
          <NavMenuSubItemList
            subItems={[
              routeNav.get(ApplicationRoutes.account.myProfile),
              routeNav.get(ApplicationRoutes.account.logout)
            ]}></NavMenuSubItemList>
        </Menu>
      )}
    </Flex>
  );
};
