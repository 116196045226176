import { extendTheme } from '@chakra-ui/react';
import { ChakraThemeConfig } from '@cksoftware/react-base';
import { alertTheme } from './components/alert';
import { buttonTheme } from './components/button';
import { cardTheme } from './components/card';
import { drawerTheme } from './components/drawer';
import { formTheme } from './components/form';
import { formLabelTheme } from './components/formLabel';
import { headerTheme } from './components/heading';
import { inputTheme } from './components/input';
import { modalTheme } from './components/modal';
import { numberInputTheme } from './components/numberInput';
import { selectTheme } from './components/select';
import { switchTheme } from './components/switch';
import { tabsTheme } from './components/tab';
import { tableTheme } from './components/table';
import { textTheme } from './components/text';

const themeConfig: ChakraThemeConfig = {
  paddings: {
    tableHeightBottomPadding: 190
  }
};

export const ChakraTheme = extendTheme({
  ...themeConfig,
  components: {
    Input: inputTheme,
    Button: buttonTheme,
    Select: selectTheme,
    NumberInput: numberInputTheme,
    Form: formTheme,
    Text: textTheme,
    Modal: modalTheme,
    Heading: headerTheme,
    Tabs: tabsTheme,
    Card: cardTheme,
    Table: tableTheme,
    Switch: switchTheme,
    Drawer: drawerTheme,
    Alert: alertTheme,
    FormLabel: formLabelTheme
  },

  styles: {
    global: {
      body: {
        background: '#faf9f67f'
      }
    }
  },
  colors: {
    primary: {
      "50": "#fff5e5",
      "100": "#ffe3cc",
      "200": "#ffc799",
      "300": "#ffaa66",
      "400": "#ff8e33",
      "500": "#ff9328",
      "600": "#cc7621",
      "700": "#99581a",
      "800": "#663b13",
      "900": "#331d0b"
    },
    secondary: {
      "50": "#ffe5f0",
      "100": "#ffccd9",
      "200": "#ff99b3",
      "300": "#ff668c",
      "400": "#ff3366",
      "500": "#ff003f",
      "600": "#cc0033",
      "700": "#990027",
      "800": "#66001a",
      "900": "#33000d"
    },
    tertiary: {
      50: '#f4e0e8',
      100: '#e0b3c4',
      200: '#c8809b',
      300: '#b14d72',
      400: '#9d2664',
      500: '#64154a', // Base color
      600: '#5a1e3f',
      700: '#441434',
      800: '#2d0b24',
      900: '#180613'
    }
  }
});
