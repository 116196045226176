import {
  RouteAuthenticationRequirement,
  RouteItem,
} from "@cksoftware/react-base";
import { NarrowLayout } from "../components/layout/narrowLayout";
import { AppRoles } from "../constants/appRoles";
import { ApplicationRoutes } from "../constants/appRoutes";

export const AdminNavItems: RouteItem = {
  text: "Admin",
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.UserAdmin, AppRoles.Admin],
  layout: <NarrowLayout />,
  subItems: [
    {
      text: "Manage Users",
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.admin.users,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import("../features/userAdmin/ManageUsers");
        return { Component: component.ManageUsers };
      },
    },
  ],
};
