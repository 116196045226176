export class Constants {
  public static authPrefix = 'auth';
}

export const AppRoles = {
  Admin: 'Admin',
  UserAdmin: 'UserAdmin',
  Inspector: 'Inspector'
};

export const AllAccessRoles = [AppRoles.Admin];
