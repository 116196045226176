import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const normalCard = definePartsStyle({
  container: {
    background: 'white',
    border: '1px solid #ebebeb',
    borderRadius: '8px',
    boxShadow: 'md',
    padding: '8px'
  },
  header: {
    padding: '10px 10px 0px 10px'
  },
  body: {
    padding: '10px'
  }
});

const fieldArray = definePartsStyle({
  container: {
    background: 'rgb(243, 243, 243)',
    border: '1px solid #ebebeb',
    borderRadius: '4px',
    boxShadow: 'none'
  },
  header: {
    padding: '10px'
  },
  body: {
    padding: 'px'
  }
});

export const cardTheme = defineMultiStyleConfig({
  variants: {
    normalCard,
    fieldArray
  },
  defaultProps: {
    variant: 'normalCard'
  }
});
